<template>
  <div class="box" style="background-color: #f8fcff;">
    <el-card style="width: 1200px;margin: 20px auto;" class="box-card">
      <div slot="header" class="stepsSctive clearfix" style="padding: 0 0 20px 0;">
        <span style="font-size: 18px;">确认订单</span>
        <el-steps align-center :active="active" style="width: 370px;float: right;">
          <el-step description="我的购物车" icon="el-icon-s-help"></el-step>
          <el-step description="填写核对订单" icon="el-icon-s-help"></el-step>
          <el-step description="交易成功" icon="el-icon-s-help"></el-step>
        </el-steps>
      </div>
      <h2 style="margin-bottom: 20px;">收货地址</h2>
      <div class="text item shippingAddress">
        <el-card v-for="(item, index) in !expCollShow ? address.slice(0, 2) : address" :key="index"
          @click.native="changeaddress(item, index)" :body-style="{ padding: '28px 20px 20px 20px' }"
          style="width: 360px;height: 130px;position: relative;" :class="
            index == addressIndex ? 'box-card borderdasherred' : 'box-card'
          ">
          <p v-if="item.isDefault"
            style="width: 80px;height: 24px;background: #AAAAAA;position: absolute;top: 0;left: 0;text-align: center;line-height: 24px;color: white;">
            默认地址
          </p>
          <div class="text item">
            <p class="p1">
              <span>{{ item.name }}</span><span>{{ item.phone }}</span>
            </p>
            <p class="p2">{{ item.address }}</p>
            <p style="float: right;" class="p3">
              <span @click="editAddress(item.id)" style="margin-right: 20px;cursor: pointer;"><i
                  style="color: red;margin-right: 3px;" class="el-icon-edit"></i>编辑</span>
              <span @click="deleteAddress(item.id)" style="margin-right: 20px;cursor: pointer;"><i
                  style="color: red;margin-right: 3px;" class="el-icon-delete"></i>删除</span>
            </p>
          </div>
        </el-card>
        <el-card @click.native="addAddress()" style="width: 360px;height: 130px;" class="box-card">
          <div class="text item">
            <p style="text-align: center;line-height: 90px;">+新增收货地址</p>
          </div>
        </el-card>
        <div class="expandCollapse">
          <el-button size="small" v-if="address.length > 2" @click="clickExpColl">{{ expCollShow ? "收起" : "查看更多" }}
          </el-button>
        </div>
      </div>
      <h2 style="margin-bottom: 20px;">提货方式</h2>
      <div style="border-bottom: solid 1px #EEEEEE;" class="pay">
        <p style="margin-bottom: 30px;">
          <span @click="changePickUp(0)" :style="
              pickUp == 0
                ? 'position: relative;cursor: pointer;'
                : 'border: 1px solid #EEEEEE;cursor: pointer;'
            ">
            到厂自提<img v-if="pickUp == 0" style="position: absolute;right: 0;bottom: 0;" src="/img/xuanzhong.png"
              alt="" />
          </span>
          <span @click="changePickUp(1)" :style="
              pickUp == 1
                ? 'position: relative;cursor: pointer;'
                : 'border: 1px solid #EEEEEE;cursor: pointer;'
            ">
            物流发货<img v-if="pickUp == 1" style="position: absolute;right: 0;bottom: 0;" src="/img/xuanzhong.png"
              alt="" />
          </span>
        </p>
        <div class="textarea-remark">
          <h3 style="margin-bottom: 10px;float:left;">请留言:</h3>
          <p style="margin-bottom: 10px;width:40%;float:left;">
            <el-input type="textarea" :rows="2" v-model="shop.Remark" placeholder="请留言" />
          </p>
        </div>
      </div>
      <div class="list">
        <h2 style="margin: 0 0 30px 0;padding-top: 30px;">送货清单</h2>
        <p v-for="(pro, index) in productList" :key="index" class="pList"
          style="padding-bottom: 30px;border-bottom: solid 1px #EEEEEE;">
          <span><img :src="pro.filePath" alt="" /></span>
          <span>
            <p style="width: 260px;">{{ pro.name }}</p>
          </span>
          <span>x{{ pro.numCount }}</span>
          <span>￥{{ pro.perPrice }}</span>
          <span>￥{{ pro.price }}</span>
        </p>
      </div>
      <div class="listInfo" style="float: right;">
        <p>
          <span class="sp1"><span style="color: red;">{{ sumCount }}</span>件商品，总商品金额：</span><span>￥{{ sumPrice }}</span>
        </p>
        <!-- <p><span class="sp1">返现：</span><span>￥69.00</span></p>
        <p><span class="sp1">运费：</span><span>-￥00.00</span></p>
        <p><span class="sp1">服务费：</span><span>￥00.00</span></p>
        <p><span class="sp1">退换无忧：</span><span>￥00.00</span></p> -->
      </div>
    </el-card>
    <el-card style="width: 1200px;margin: 20px auto;" class="box-card">
      <div class="footer">
        <p>
          应付总额：<span style="color: red;font-weight: 600;">￥{{ sumPrice }}</span>
        </p>
        <p style="color: #999999;">寄送至： {{ peisongaddress }}</p>
        <el-button @click="pay" style="background-color: #C91C25;color: white;width: 150px;height: 50px;">立即购买
        </el-button>
      </div>
    </el-card>
    <el-dialog title="收货地址" :close-on-click-modal="false" :visible.sync="dialogFormVisible">
      <el-form ref="shop" :model="shop" label-width="80px">
        <el-form-item style="display:none;" label="id" prop="Id">
          <el-input style="width: 63%;" v-model="shop.Id"></el-input>
        </el-form-item>
        <el-form-item :rules="[{ required: true, message: '请输入收货人' }]" label="收货人" prop="Name">
          <el-input @change="changeName" style="width: 63%;" placeholder="请输入收货人" v-model="shop.Name"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" :rules="[
            { required: true, message: '请输入手机号' },
            { pattern: /^1[34578]\d{9}$/, message: '请输入正确手机号' }
          ]" prop="Phone">
          <el-input style="float: left;width: 63%;margin-right: 50px;" placeholder="请输入11位手机号码" v-model="shop.Phone">
          </el-input>
        </el-form-item>
        <el-form-item :rules="[{ required: true, message: '请选择地区' }]" label="地区" prop="AreaId">
          <el-cascader style="width: 63%;" v-model="shop.AreaId" :options="areas"></el-cascader>
        </el-form-item>
        <el-form-item :rules="[{ required: true, message: '请填写详细地址' }]" label="详细地址" prop="Address">
          <el-input style="width: 63%;" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请填写详细地址"
            v-model="shop.Address"></el-input>
        </el-form-item>
        <!-- <el-form-item label="邮编">
	      <el-input v-model="shop.postcode"></el-input>
	    </el-form-item> -->
        <el-form-item prop="IsDefault">
          <p>
            <el-checkbox v-model="shop.IsDefault">设为默认收货地址</el-checkbox>
          </p>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('shop')">取 消</el-button>
        <el-button style="background: #24A2AA;color: white;" @click="saveUpdate('shop')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAreaList } from "@/api/personalCenter";
import { mapState } from "vuex";
export default {
  name: "fillInTheOrder",
  computed: {
    ...mapState({
      addressList: (state) => {
        return state.fillInTheOrder.addressList;
      },
      get_Product: (state) => {
        return state.fillInTheOrder.get_Product;
      },
      get_CreateOrder: (state) => {
        return state.fillInTheOrder.get_CreateOrder;
      },
      get_GetShoppingAddress: (state) => {
        return state.fillInTheOrder.get_GetShoppingAddress;
      },
      get_CreateShoppingAddress: (state) => {
        return state.fillInTheOrder.get_CreateShoppingAddress;
      },
      get_UpdateShoppingAddress: (state) => {
        return state.fillInTheOrder.get_UpdateShoppingAddress;
      },
    }),
  },
  data() {
    return {
      active: 2,
      products: [
        {
          productId: 23,
          count: 1,
        },
      ],
      address: [],
      addressIndex: 0,
      pickUp: 1,
      productList: [],
      sumCount: 0,
      sumPrice: 0,
      peisongaddress: "",
      expCollShow: false,
      dialogFormVisible: false,
      shop: {
        Name: "",
        AreaId: "",
        Address: "",
        IsDefault: "",
        Phone: "",
        Id: 0,
        Remark: "",
      },
      areas: [], //地区下拉框
    };
  },
  watch: {
    expCollShow: function (n, o) {
      // this.address =
    },
  },
  mounted() {
    var json = this.$route.query.shopList;
    this.products = JSON.parse(json);
    this.onload();
    this.productVm();
    this.getAreaList();
  },
  methods: {
    onload() {
      this.$store
        .dispatch("fillInTheOrder/ShoppingAddressList", {
          PageIndex: 1,
          PageSize: 20,
        })
        .then(() => {
          this.address = this.addressList;
          this.peisongaddress =
            this.address.length > 0 ? this.address[0].address : "";
          this.addressIndex = 0;
        });
    },
    clickExpColl() {
      this.expCollShow = !this.expCollShow;
    },
    productVm() {
      var that = this;
      var ids = [];
      that.products.forEach(function (item) {
        ids.push(item.productId);
      });
      that.$store
        .dispatch("fillInTheOrder/GetProductList", {
          ids: ids,
        })
        .then(() => {
          var data = that.get_Product;
          data.forEach(function (item) {
            var count = that.products.filter((d) => d.productId == item.id)[0]
              .count;
            item.numCount = count;
            item.price = count * item.perPrice;
            that.productList.push(item);
          });
          that.getSum();
        });
    },
    changeaddress(item, index) {
      this.addressIndex = index;
      this.peisongaddress = this.address[index].address;
    },
    changePickUp(obj) {
      this.pickUp = obj;
    },
    getSum() {
      var price = 0;
      var count = 0;
      if (this.productList.length > 0) {
        for (var i = 0; i < this.productList.length; i++) {
          price = price + this.productList[i].price;
          count = count + this.productList[i].numCount;
        }
      }
      this.sumPrice = price.toFixed(2);
      this.sumCount = count;
    },
    pay() {
      var that = this;
      var submitOrderVms = [];
      for (var i = 0; i < that.productList.length; i++) {
        submitOrderVms.push({
          productId: that.productList[i].id,
          numCount: that.productList[i].numCount,
        });
      }
      var values = [];
      var dizhi = that.address[that.addressIndex];
      if (!dizhi) {
        this.$message.warning("请填写收货地址");
        return;
      }
      values.pickUp = that.pickUp;
      values.Name = dizhi.name;
      values.Address = dizhi.address;
      values.Phone = dizhi.phone;
      values.Remark = that.shop.Remark;
      values.SubmitOrderVms = submitOrderVms;
      that.$store
        .dispatch("fillInTheOrder/CreateOrder", {
          vm: values,
        })
        .then(() => {
          if (that.get_CreateOrder.success) {
            that.$message.success("提交成功");
            that.$router.push({
              path: "/pay/pay",
              query: {
                orderId: that.get_CreateOrder.orderId,
              },
            });
            // that.$router.go(-1);
          } else {
            that.$message.error("提交失败");
          }
        });
    },
    addAddress() {
      this.dialogFormVisible = true;
      this.shop.IsDefault = true;
    },
    changeName() {
      this.$forceUpdate();
    },
    editAddress(id) {
      this.$store
        .dispatch("fillInTheOrder/GetShoppingAddress", {
          id: id,
        })
        .then(() => {
          var data = this.get_GetShoppingAddress;
          // that.resetForm('shop');
          Object.assign(this.shop, {
            Name: data.name,
            AreaId: [
              data.area.provinceCode,
              data.area.countyCode,
              String(data.area.id),
            ],
            Address: data.address,
            Phone: data.phone,
            IsDefault: data.isDefault,
            Id: data.id,
          });
          this.dialogFormVisible = true;
        });
    },
    deleteAddress(id) {
      this.$store
        .dispatch("fillInTheOrder/DeleteShoppingAddress", {
          id: id,
        })
        .then(() => {
          this.onload();
        });
    },
    async getAreaList() {
      try {
        const { success, result } = await getAreaList();
        if (success === true) {
          this.areas = result;
        }
      } catch {}
    },
    saveUpdate(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var that = this;
          var data = {
            Id: that.shop.Id,
            AreaId: that.shop.AreaId[2],
            Address: that.shop.Address,
            Name: that.shop.Name,
            Phone: that.shop.Phone,
            IsDefault: that.shop.IsDefault,
          };
          if (data.Id > 0) {
            this.$store
              .dispatch("fillInTheOrder/UpdateShoppingAddress", {
                dto: data,
              })
              .then(() => {
                if (that.get_UpdateShoppingAddress.success) {
                  that.infoAddress(formName);
                }
              });
          } else {
            this.$store
              .dispatch("fillInTheOrder/CreateShoppingAddress", {
                dto: data,
              })
              .then(() => {
                if (that.get_CreateShoppingAddress.success) {
                  that.infoAddress(formName);
                }
              });
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    infoAddress(formName) {
      this.dialogFormVisible = false;
      this.onload();
      this.resetForm(formName);
      // let userInfo = getUserInfo();
      // getShoppingCartCount().then((cartRes) => {
      //   if (cartRes.success) {
      //     this.$store.state.resCount = cartRes.result.count;
      //   }
      // });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
      this.shop = {
        Name: "",
        AreaId: "",
        Address: "",
        IsDefault: "",
        Phone: "",
        Id: 0,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.borderdasherred {
  border: dashed 1px #c91c25;
}

.stepsSctive {
  /deep/.is-finish {
    color: #2ec4cd;
  }

  /deep/.is-process {
    color: rgb(192 196 204);
  }

  /deep/.el-step__description {
    padding-top: 6px;
  }
}

.textarea-remark {
  overflow: hidden;

  .el-textarea {
    margin-left: 10px;

    /deep/.el-textarea__inner:focus {
      border-color: #2ec4cd;
    }
  }
}

.expandCollapse {
  text-align: right;
  padding-right: 80px;
  padding-bottom: 20px;

  /deep/.el-button:focus {
    background: #fff;
    border-color: #dcdfe6;
    color: #606266;
  }

  /deep/.el-button:hover {
    background: #8af5f1;
    border-color: #48d1cc;
    color: #fff;
  }
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;

  .p1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .p2 {
    color: #999999;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .p3 {
    span {
    }
  }
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}

.pay {
  p {
    span {
      width: 120px;
      display: inline-block;
      height: 36px;
      border: 1px solid #c91c25;
      text-align: center;
      line-height: 36px;
      margin-right: 20px;
    }
  }
}

.list {
  .pList {
    display: flex;
    justify-content: space-between;
    padding-right: 320px;

    span {
      img {
        width: 96px;
        height: 96px;
        object-fit: cover;
      }
    }
  }
}

.listInfo {
  p {
    margin: 20px 0 20px 0;

    .sp1 {
      text-align: right;
      width: 180px;
      display: inline-block;
      margin-right: 30px;
      color: #666666;
    }
  }
}

.shippingAddress {
  border-bottom: solid 1px #eeeeee;
  overflow: hidden;

  .box-card {
    float: left;
    margin-right: 1%;
    margin-bottom: 10px;
  }
}

.footer {
  text-align: right;
  margin: 0 30px 0 0;

  p {
    margin-bottom: 19px;
  }
}
</style>
